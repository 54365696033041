<template>
    <div class="content-wrapper overflow-visible">
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{row.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left">{{rowReg.ar_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{row.cg_label || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>
                        
                        <div class="phi-info d-flex flex-column col-md-3 ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>
        <div class="content">
            <template v-if="!rowReg.ar_is_pat_approved && rowReg.ar_is_created_pat != 'Y'">
            <validation-observer ref="VFormSubmitApproval">
            <b-form @submit.prevent="doSubmitApproval" class="card">
                <div class="card">
                    <div class="card-header bg-info">
                        <h5 class="card-title font-weight-semibold">Form Persetujuan Tindakan Pasien</h5>
                    </div>
                    <div class="card-body">
                        <div class="mb-3">
                            <table class="table table-bordered table-sm">
                                <tbody>
                                    <tr>
                                        <td width="50%">
                                            <div class="result_tab">
                                                <h4>Petugas yang Melaksanakan</h4>
                                                <p>{{row.bu_full_name||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="50%">
                                            <div class="result_tab">
                                                <h4>Nama Keluarga Pasien <strong class="text-danger">*</strong></h4>
                                                <b-form-input :formatter="normalText" v-model="row.appas_pasien_family"
                                                    type="text" class="form-control" />
                                                <VValidate name="Nama Keluarga Pasien" v-model="row.appas_pasien_family"
                                                    :rules="toValidate(mrValidation.appas_pasien_family)" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="result_tab">
                                                <h4>Tindakan yang Dilakukan</h4>
                                                <p>{{row.appas_tindakan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                                <h4>Konsekuensi dari Tindakan <strong class="text-danger">*</strong></h4>
                                                <b-form-input :formatter="normalText" v-model="row.appas_konsekuensi"
                                                    type="text" class="form-control" />
                                                <VValidate name="Konsekuensi dari Tindakan" v-model="row.appas_konsekuensi"
                                                    :rules="toValidate(mrValidation.appas_konsekuensi)" />

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="result_tab">
                                                <h4>Tanggal Pemberian Penjelasan Tindakan</h4>
                                                <p>{{row.arm_submit_date | moment("DD MMM YYYY")}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                                <h4>Jam Pemberian Penjelasan Tindakan <strong class="text-danger">*</strong></h4>
                                                <div class="input-group">
                                                    <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                                                    <vue-timepicker format="HH:mm" input-class="form-control" v-model="row.times">
                                                    </vue-timepicker>
                                                </div>
                                                <template v-if="Object.keys(row.times||{}).length">
                                                <div v-if="!row.times.HH && !row.times.mm" id="Jam Pemberian Penjelasan Tindakan "
                                                    class="label_error" style="">The Waktu. Kejadian field is required</div>
                                                </template>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="result_tab">
                                                <h4 class="mb-2">Apakah Pihak Pasien Setuju dengan Tindakan?</h4>
                                                <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                                    v-model="row['appas_is_setuju']" />

                                                <VValidate name="Apakah kejadian yang sama pernah terjadi"
                                                    v-model="row.appas_is_setuju"
                                                    :rules="toValidate(mrValidation.appas_is_setuju)" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                                <h4 class="mb-1">Unggah Dokumen Persetujuan</h4>
                                                <Uploader v-model="row.appas_file" isDocument type="docimage" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table class="table table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th colspan="4">Yang Membuat Pernyataan,</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="result_tab">
                                                <h4 class="mb-2">Dokter yang Memberi Penjelasan</h4>
                                                <b-form-input :formatter="normalText" v-model="row.appas_dokter_pemberi_penjelasan"
                                                    type="text" class="form-control" />
                                                <VValidate name="Dokter yang Memberi Penjelasan" v-model="row.appas_dokter_pemberi_penjelasan"
                                                    :rules="toValidate(mrValidation.appas_dokter_pemberi_penjelasan)" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                                <h4 class="mb-2">Pasien/Keluarga yang Menerima Penjelasan</h4>
                                                <b-form-input :formatter="normalText" v-model="row.appas_pasien_penerima_penjelasan"
                                                    type="text" class="form-control" />
                                                <VValidate name="Pasien/Keluarga yang Menerima Penjelasan" v-model="row.appas_pasien_penerima_penjelasan"
                                                    :rules="toValidate(mrValidation.appas_pasien_penerima_penjelasan)" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                                <h4 class="mb-2">Saksi 1</h4>
                                                <b-form-input :formatter="normalText" v-model="row.appas_saksi1"
                                                    type="text" class="form-control" />
                                                <VValidate name="Saksi 1" v-model="row.appas_saksi1"
                                                    :rules="toValidate(mrValidation.appas_saksi1)" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                                <h4 class="mb-2">Saksi 2</h4>
                                                <b-form-input :formatter="normalText" v-model="row.appas_saksi2"
                                                    type="text" class="form-control" />
                                                <VValidate name="Saksi 2" v-model="row.appas_saksi2"
                                                    :rules="toValidate(mrValidation.appas_saksi2)" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="card" v-if="row.appas_is_setuju == 'Y'">
                    <div class="card-header bg-info">
                        <h5 class="card-title font-weight-semibold mr-auto">Form Tindakan</h5>
                    </div>
                    
                    <template v-if="row.appas_is_icd9 == 'N'">
                        <div class="row">
                            <div class="col-md-12 p-4">
                                <div class="mb-3">
                                <table class="table table-bordered table-sm">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="result_tab">
                                                    <h4>Petugas yang Melaksanakan</h4>
                                                    <p>{{row.bu_full_name||"-"}}</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                    <h4>Tanggal Pelaksanaan Tindakan</h4>
                                                    <p>{{row.arm_submit_date | moment("DD MMM YYYY")}}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <div class="card border shadow-0" v-if="(diagnosaPerawat||[]).length">
                                    <div class="card-header bg-info">
                                        <h5 class="card-title font-weight-semibold">Tindakan Keperawatan</h5>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Diagnosa</th>
                                                    <th>Tindakan Keperawatan</th>
                                                    <th width="185">PPA</th>
                                                    <th>Waktu</th>
                                                    <th width="64">Aksi</th>
                                                </tr>
                                            </thead>
                                            <div class="display-c" v-for="(v,k) in (diagnosaPerawat||[])" :key="k">
                                            <tbody>
                                                <tr v-for="(vti,kti) in (v.aaksd_tindakan_keperawatan||[])" :key="kti">
                                                    <td v-if="kti == 0"
                                                        :rowspan="(v.aaksd_tindakan_keperawatan||[]).length">
                                                        {{v.msd_name||"-"}}</td>
                                                    <td>{{vti.name||"-"}}</td>
                                                    <td>      
                                                        <span>{{vti.ppa||"-"}}</span><br/>
                                                        <span>{{vti.ppaLainnya||"-"}}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="vti.dates">
                                                            {{vti.dates | moment("DD MMM YYYY")}}
                                                            <span v-if="vti.time" class="ml-2">
                                                                {{vti.time.HH}}:{{vti.time.mm}}
                                                            </span>
                                                        </span>
                                                        <span v-else> - </span>
                                                    </td>
                                                    <td>
                                                        <a href="javascript:;" data-toggle="modal"
                                                            data-target="#FollowupTindakan"
                                                            class="btn alpha-primary border-primary text-primary-800 btn-icon rounded-round"
                                                            data-popup="tooltip" @click="openDetailFollowUp(vti,kti,k)"
                                                            v-b-tooltip.hover :title="vti.followup ? 'Update Follow Up Tindakan' : 'Tambah Follow Up Tindakan'"><i
                                                                :class=" vti.followup ? 'icon-file-text2' : 'icon-file-plus'"></i></a>
                                                    </td>
                                                </tr>
                                                <tr v-if="(v.aaksd_tindakan_keperawatan||[]).length">
                                                    <td> - </td>
                                                    <td>{{v.aaksd_informasi_tindakan||"-"}}</td>
                                                    <td>      
                                                        <span>Perawat</span>
                                                        <span>{{v.aaksd_nama_perawat||""||"-"}}</span>
                                                    </td>
                                                    <td>
                                                    
                                                        <span>
                                                            {{row.dates | moment("DD MMM YYYY")}}
                                                            <span class="ml-2">
                                                                {{v.aaksd_tindakan_pada | moment("h:mm") }}
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href="javascript:;" @click="openDetailFollowUpParent(v,k)" data-toggle="modal"
                                                        data-target="#FollowupTindakan"
                                                        class="btn alpha-primary border-primary text-primary-800 btn-icon rounded-round"
                                                        data-popup="tooltip"
                                                        
                                                        v-b-tooltip.hover :title="v.aaksd_follow_up ? 'Update Follow Up Tindakan' : 'Tambah Follow Up'"><i
                                                            :class="v.aaksd_follow_up ? 'icon-file-text2' : 'icon-file-plus'"></i></a>
                                                    </td>
                                                </tr>
                                                <tr v-if="!(v.aaksd_tindakan_keperawatan||[]).length">
                                                    <td colspan="99">Tidak ada Data</td>
                                                </tr>
                                            </tbody>
                                            </div>
                                            <div class="display-c" v-if="!(diagnosaPerawat||[]).length">
                                                <tbody>
                                                    <tr>
                                                        <td colspan="99">Tidak Ada Data</td>
                                                    </tr>
                                                </tbody>
                                            </div>
                                        </table>
                                    </div>
                                </div>
                                <div class="card border shadow-0">                                
                                    <table class="table table-bordered">
                                        <div class="display-c">
                                            <tbody>
                                                <tr colspan="99">
                                                    <td>
                                                        <div class="result_tab">
                                                            <h4 class="mb-2">Apakah Pasien Akan Ditangani Oleh HAIs?</h4>
                                                            <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                                                v-model="rowReg['ar_is_need_hais']" />

                                                            <VValidate name="Apakah Pasien Akan Ditangani Oleh Hais"
                                                                v-model="rowReg.ar_is_need_hais"
                                                                :rules="{required : 1}" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </div>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                    <div class="row">
                        <div class="col-md-12 p-4">
                            <div class="mb-3">
                                <table class="table table-bordered table-sm">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="result_tab">
                                                    <h4>Petugas yang Melaksanakan</h4>
                                                    <p>{{row.bu_full_name||"-"}}</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                    <h4>Tanggal Pelaksanaan Tindakan</h4>
                                                    <p>{{row.arm_submit_date | moment("DD MMM YYYY")}}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="result_tab">
                                                    <h4>Tindakan yang Dilakukan</h4>
                                                    <p>{{row.appas_tindakan||"-"}}</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                    <h4 class="mb-2">Apakah Pasien Akan Ditangani Oleh Hais?</h4>
                                                    <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                                        v-model="rowReg['ar_is_need_hais']" />

                                                    <VValidate name="Apakah Pasien Akan Ditangani Oleh Hais"
                                                        v-model="rowReg.ar_is_need_hais"
                                                        :rules="{required : 1}" />
                                                </div>
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                            <div class="row g-2">
                                <div class="col-md-3">
                                    <div>
                                        <label for="">Waktu Mulai Tindakan</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                                            <vue-timepicker format="HH:mm" input-class="form-control" v-model="row.tindakan_mulai">
                                            </vue-timepicker>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div>
                                        <label for="">Waktu Selesai Tindakan</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                                            <vue-timepicker format="HH:mm" input-class="form-control" v-model="row.tindakan_selesai">
                                            </vue-timepicker>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100"></div>
                                <div class="col-md-6">
                                    <div>
                                        <label for="">Alat Medis yang Digunakan</label>
                                        <b-textarea v-model="row.appas_alat" rows="3" class="form-control"></b-textarea>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div>
                                        <label for="">Bahan Medis Habis Pakai</label>
                                        <b-textarea v-model="row.appas_bahan_medis" rows="3" class="form-control"></b-textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </template>
                </div>
                <div class="card-footer">
                    <div class="text-right">
                        <a href="javascript:;" @click="$router.back()" class="btn btn-transparent">Batal</a>
                        <button class="btn btn-success btn-labeled btn-labeled-left" type="submit">
                            <b><i class="icon-checkmark"></i></b>
                            Simpan
                        </button>
                    </div>
                </div>
            </b-form>
            </validation-observer>
            </template>

            <template v-else>
                <div class="card">
                    <div class="card-header bg-info">
                        <h5 class="card-title font-weight-semibold">Form Persetujuan Tindakan Pasien</h5>
                    </div>
                    <div class="card-body">
                        <div class="mb-3">
                            <table class="table table-bordered table-sm">
                                <tbody>
                                    <tr>
                                        <td width="50%">
                                            <div class="result_tab">
                                                <h4>Petugas yang Melaksanakan</h4>
                                                <p>{{row.bu_full_name||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="50%">
                                            <div class="result_tab">
                                                <h4>Nama Keluarga Pasien <strong class="text-danger">*</strong></h4>
                                                <p>{{row.appas_pasien_family||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="result_tab">
                                                <h4>Tindakan yang Dilakukan</h4>
                                                <p>{{row.appas_tindakan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                                <h4>Konsekuensi dari Tindakan <strong class="text-danger">*</strong></h4>
                                                <p>{{row.appas_konsekuensi||"-"}}</p>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="result_tab">
                                                <h4>Tanggal Pemberian Penjelasan Tindakan</h4>
                                                <p>{{row.arm_submit_date | moment("DD MMM YYYY")}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                                <h4>Jam Pemberian Penjelasan Tindakan <strong class="text-danger">*</strong></h4>
                                                <p>{{row.appas_jam_pemberian_tindakan||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="result_tab">
                                                <h4 class="mb-2">Apakah Pihak Pasien Setuju dengan Tindakan?</h4>
                                                <p>{{row.appas_is_setuju == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                                <h4 class="mb-1">Unggah Dokumen Persetujuan</h4>
                                                <a class="btn_view" :href="uploader(row.appas_file)" target="_blank"><i class="icon-file-download"></i></a>

                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table class="table table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th colspan="4">Yang Membuat Pernyataan,</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="result_tab">
                                                <h4 class="mb-2">Dokter yang Memberi Penjelasan</h4>
                                                <p>{{row.appas_dokter_pemberi_penjelasan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                                <h4 class="mb-2">Pasien/Keluarga yang Menerima Penjelasan</h4>
                                                <p>{{row.appas_pasien_penerima_penjelasan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                                <h4 class="mb-2">Saksi 1</h4>
                                                <p>{{row.appas_saksi1||"-"}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                                <h4 class="mb-2">Saksi 2</h4>
                                                <p>{{row.appas_saksi2||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <template v-if="row.appas_is_icd9 == 'N'">
                        <div class="row">
                            <div class="col-md-12 p-4">
                                <div class="mb-3">
                                <table class="table table-bordered table-sm">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="result_tab">
                                                    <h4>Petugas yang Melaksanakan</h4>
                                                    <p>{{row.bu_full_name||"-"}}</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                    <h4>Tanggal Pelaksanaan Tindakan</h4>
                                                    <p>{{row.arm_submit_date | moment("DD MMM YYYY")}}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <div class="card border shadow-0">
                                    <div class="card-header bg-info">
                                        <h5 class="card-title font-weight-semibold">Tindakan Keperawatan</h5>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Diagnosa</th>
                                                    <th>Tindakan Keperawatan</th>
                                                    <th width="185">PPA</th>
                                                    <th>Waktu</th>
                                                    <th>Follow Up</th>
                                                </tr>
                                            </thead>
                                            <div class="display-c" v-for="(v,k) in (diagnosaPerawat||[])" :key="k">
                                            <tbody>
                                                <tr v-for="(vti,kti) in (v.aaksd_tindakan_keperawatan||[])" :key="kti">
                                                    <td v-if="kti == 0"
                                                        :rowspan="(v.aaksd_tindakan_keperawatan||[]).length">
                                                        {{v.msd_name||"-"}}</td>
                                                    <td>{{vti.name||"-"}}</td>
                                                    <td>      
                                                        <span>{{vti.ppa||"-"}}</span><br/>
                                                        <span>{{vti.ppaLainnya||"-"}}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="vti.dates">
                                                            {{vti.dates | moment("DD MMM YYYY")}}
                                                            <span v-if="vti.time" class="ml-2">
                                                                {{vti.time.HH}}:{{vti.time.mm}}
                                                            </span>
                                                        </span>
                                                        <span v-else> - </span>
                                                    </td>
                                                    <td>
                                                        {{vti.followup||"-"}}
                                                    </td>
                                                </tr>
                                                <tr v-if="(v.aaksd_tindakan_keperawatan||[]).length">
                                                    <td> - </td>
                                                    <td>{{v.aaksd_informasi_tindakan||"-"}}</td>
                                                    <td>      
                                                        <span>Perawat</span>
                                                        <span>{{v.aaksd_nama_perawat||""||"-"}}</span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            {{row.dates | moment("DD MMM YYYY")}}
                                                            <span class="ml-2">
                                                                {{v.aaksd_tindakan_pada | moment("h:mm") }}
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {{v.aaksd_follow_up||"-"}}
                                                    </td>
                                                </tr>
                                                <tr v-if="!(v.aaksd_tindakan_keperawatan||[]).length">
                                                    <td colspan="99">Tidak ada Data</td>
                                                </tr>
                                            </tbody>
                                            </div>
                                            
                                            <div class="display-c" v-if="!(diagnosaPerawat||[]).length">
                                                <tbody>
                                                    <tr>
                                                        <td colspan="99">Tidak Ada Data</td>
                                                    </tr>
                                                </tbody>
                                            </div>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div class="row">
                            <div class="col-md-12 p-4">
                                <div class="mb-3">
                                    <table class="table table-bordered table-sm">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="result_tab">
                                                        <h4>Petugas yang Melaksanakan</h4>
                                                        <p>{{row.bu_full_name||"-"}}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="result_tab">
                                                        <h4>Tanggal Pelaksanaan Tindakan</h4>
                                                        <p>{{row.arm_submit_date | moment("DD MMM YYYY")}}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <div class="result_tab">
                                                        <h4>Tindakan yang Dilakukan</h4>
                                                        <p>{{row.appas_tindakan||"-"}}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row g-2">
                                    <div class="col-md-3">
                                        <div>
                                            <label for="">Waktu Mulai Tindakan</label>
                                            <p>{{row.appas_start_tindakan||"-"}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div>
                                            <label for="">Waktu Selesai Tindakan</label>
                                            <p>{{row.appas_end_tindakan||"-"}}</p>
                                        </div>
                                    </div>
                                    <div class="w-100"></div>
                                    <div class="col-md-6">
                                        <div>
                                            <label for="">Alat Medis yang Digunakan</label>
                                            <p>{{row.appas_alat||"-"}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>
                                            <label for="">Bahan Medis Habis Pakai</label>
                                            <p>{{row.appas_bahan_medis||"-"}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                
            </template>
            
            <b-modal v-model="openFollowUpTindakan" :title="'Tindakan Keperawatan'" size="sm" ok-title="Submit"
                @ok="submitFollowUp">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Follow Up Tindakan<small class="txt_mandatory">*</small></label>
                            <b-textarea v-model="rowFollowUp.followup" :formatter="normalText" rows="6" class="form-control"
                                placeholder="Informasi Tindakan" spellcheck="false"></b-textarea>
                        </div>
                    </div>
                </div>
            </b-modal>
            
            
            <b-modal v-model="openFollowUpTindakanParent" :title="'Tindakan Keperawatan'" size="sm" ok-title="Submit"
                @ok="submitFollowUpParent">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Follow Up Tindakan<small class="txt_mandatory">*</small></label>
                            <b-textarea v-model="rowFollowUp.aaksd_follow_up" :formatter="normalText" rows="6" class="form-control"
                                placeholder="Informasi Tindakan" spellcheck="false"></b-textarea>
                        </div>
                    </div>
                </div>
            </b-modal>
            <!-- /dashboard content -->
        </div>
    </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
const moment = require('moment')

export default {
  extends: GlobalVue,
  data() {
    return {
        rowReg: {},
        diagnosaPerawat: [],
        openFollowUpTindakan: false,
        openFollowUpTindakanParent: false,
        rowFollowUp: {}
    }
  },
  
    components:{ 
        VueTimepicker
    },
  mounted(){
    this.apiGet()
    setTimeout(()=>{
        this.initSticky()
    },1000)
  },
  methods: {
    toValidate(val){
        return {...val}
    },
    initSticky(){
        const PageHeaderSticky = document.querySelector('.page-header-sticky')
        if(PageHeaderSticky){    
            const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
            if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                PageHeaderToggle.addEventListener('click', function(e){
                // e.preventDefault()
                PageHeaderSticky.classList.toggle('page-header-sticky-open')
                if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                    PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                    PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                    PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                }else{
                    PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                    PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                    PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                }
                })    
            } 
        }
    },
    
    doSubmitApproval(){
        if(this.row.appas_is_icd9 == 'N' && this.row.appas_is_setuju == 'Y'){
            let invalid = 0
            for(let i = 0; i < (this.diagnosaPerawat||[]).length; i++){    
                for(let j = 0; j < (this.diagnosaPerawat[i]['aaksd_tindakan_keperawatan']||[]).length; j++){ 
                    if(!this.diagnosaPerawat[i]['aaksd_tindakan_keperawatan'][j]['followup']){
                        invalid += 1
                    }
                }
            }
            if(invalid){
                return this.$swal({
                    icon: 'error',
                    title: 'Terdapat Tindakan yang belum di follow up'
                })
            }
        }
        
        this.$refs['VFormSubmitApproval'].validate().then(success=>{
            if(success){
                this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                }).then(result => {
                    if (result.value) {
                        let data = this.row
                        data.type = 'submit-approval'
                        data.ar_id = this.$route.query.regId
                        data.ar_is_need_hais = this.rowReg.ar_is_need_hais
                        data.diagnosaPerawat = this.diagnosaPerawat

                        this.loadingOverlay = true
                        Gen.apiRest(
                            "/do/"+'FollowUp',
                            {data:data}, 
                            "POST"
                        ).then(res=>{
                            this.loadingOverlay = false
                            let resp = res.data
                            resp.statusType = 200
                            this.$swal({
                                title: resp.message,
                                icon: resp.status,
                                confirmButtonText: 'Ok'
                            }).then(result => {
                                if (result.value) {
                                    if(this.rowReg.ar_is_need_hais == 'Y'){
                                        this.$router.push({name: 'Hais', params: {pageSlug: this.rowReg.ar_id}}).catch(()=>{})
                                    }else{
                                        this.$router.push({name:'Dashboard'}).catch(()=>{})
                                    }
                                }
                            })
                        }).catch(err=>{
                            this.loadingOverlay = false
                            if(err){
                                err.statusType = err.status
                                err.status = "error"
                                err.title = err.response?.data?.title
                                err.message = err.response?.data?.message
                                err.messageError = err.message
                            }
                            this.doSetAlertForm(err)
                        })
                            
                    }
                })
            }else{
                setTimeout(()=>{
                    let inv = []
                    let el = document.querySelectorAll('.label_error')
                    for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                    }
                    if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                },500)
            }
        })
    },
    
    openDetailFollowUp(v,k,kP){
        this.openFollowUpTindakan = true,
        this.rowFollowUp = v
        this.rowFollowUp.key = k
        this.rowFollowUp.keyParent = kP
    },
    
    openDetailFollowUpParent(v,k){
        this.openFollowUpTindakanParent = true,
        this.rowFollowUp = v
        this.rowFollowUp.key = k
    },

    submitFollowUp(){
        this.diagnosaPerawat[this.rowFollowUp.keyParent]['aaksd_tindakan_keperawatan'][this.rowFollowUp.key]['followup'] = this.rowFollowUp.followup
    },
    submitFollowUpParent(){
        this.diagnosaPerawat[this.rowFollowUp.key]['aaksd_follow_up'] = this.rowFollowUp.aaksd_follow_up
    }

    
  },
  watch:{
    $route(){
      this.apiGet()
    },
  }
}

</script>
 
